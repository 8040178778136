/**
 * Styles
 */
import '@seliaco/icons/style.css'
import '@seliaco/red-panda/src/index.scss'
import '@seliaco/sea-otter/src/index.scss'
/**
 * App dependencies
 */
import Vue from 'vue'
import WebApp from './App.vue'
import router from './router'
import store from './store'
/**
 * Other libraries
 */
import moment from 'moment'
/**
 * Red panda
 */
import {
  EventEmitterInstance as RedPandaEventEmitterInstance,
  $globalEvent
} from '@seliaco/red-panda'

const timeStart = new Date().getTime()
const loadingProgress = (integration) => console.info(`${integration} ${new Date().getTime() - timeStart} ms`)

const loadApp = async () => {
  const lang = 'es'

  Vue.prototype.$redPandaEventEmitter = RedPandaEventEmitterInstance
  Vue.prototype.$globalEvent = $globalEvent
  Vue.prototype.$moment = moment
  Vue.prototype.$moment.locale(lang)
  Vue.prototype.$moment.updateLocale(lang, {
    meridiem: function (hour, minute, isLowerCase) {
      if (hour < 12) {
        return isLowerCase ? 'a.m.' : 'AM'
      } else {
        return isLowerCase ? 'p.m.' : 'PM'
      }
    }
  })

  const app = new Vue({
    router,
    store,
    render: (h) => h(WebApp)
  })

  app.$mount('#app')
}

loadApp().then(async () => {
  loadingProgress('App loaded in')
})
