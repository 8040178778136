import Vue from 'vue'
import Router from 'vue-router'

const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace

Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch(error => error)
}
Router.prototype.replace = function (location) {
  return routerReplace.call(this, location).catch(error => error)
}

Vue.use(Router)

const routes = [
  {
    path: '/filters/:lang/:country',
    name: 'Filters',
    meta: {
      title: 'Selia | Filters'
    },
    component: () => import(/* webpackChunkName: "filters" */ '../components/Filters.vue'),
    props: route => ({
      lang: route.params.lang,
      country: route.params.country
    })
  },
  {
    path: '/video',
    name: 'YoutubePlayer',
    meta: {
      title: 'Selia | Youtube Player'
    },
    component: () => import(/* webpackChunkName: "youtube-player" */ '../components/YoutubePlayer.vue')
  },
  {
    path: '/audio',
    name: 'AudioPlayer',
    meta: {
      title: 'Selia | Audio Player'
    },
    component: () => import(/* webpackChunkName: "audio-player" */ '../components/AudioPlayer.vue')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

export default router
